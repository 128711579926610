import React, { Component } from 'react'

import DentisterieGeneralePage from '../containers/DentisterieGeneralePage'
import PrimaryLayout from '../components/layouts/PrimaryLayout'
import pagesInfos from '../config/i18n/pagesInfos'

const DentisterieGenerale = ({ pageContext }) => {
  return (
    <PrimaryLayout
      language={pageContext.language}
      isDisplayFromHome={false}
      page={pagesInfos.ComponentDentisterieGenerale}
    >
      <DentisterieGeneralePage />
    </PrimaryLayout>
  )
}

export default DentisterieGenerale
